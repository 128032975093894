<template>
  <v-dialog v-model="showModal" max-width="90%">

    <template v-slot:activator="{ attrs, on }">
      <slot v-bind="{on, attrs}" name="activator">
        <v-btn v-on="on" color="primary" small v-bind:attrs="attrs">Créer</v-btn>
      </slot>
    </template>

    <v-card>

      <v-toolbar color="primary" dark>
        <v-toolbar-title>
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon @click="reset">mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <vue-scroll>
        <v-card-text style="max-height: 70vh">

          <v-text-field
              v-model="creatingHotlineAccess.title"
              :error-messages="errors.title"
              label="Titre de la configuration"
              @input="errors.title = creatingHotlineAccess.title.length > 0 ? '' : 'Ce champ doit être renseigné'"
          ></v-text-field>

          <div class="d-flex flex-row align-center justify-space-between">

            <v-menu absolute offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    :value="getStart"
                    label="Date de début"
                >
                  <template v-slot:append>
                    <v-btn icon small @click="creatingHotlineAccess.start = null">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </template>

              <v-date-picker
                  v-model="creatingHotlineAccess.start"
                  :locale="$i18n.locale"
                  color="primary"
              ></v-date-picker>

            </v-menu>

            <v-menu absolute offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    :value="getEnd"
                    label="Date de fin"
                >
                  <template v-slot:append>
                    <v-btn icon small @click="creatingHotlineAccess.end = null">
                      <v-icon small>mdi-close</v-icon>
                    </v-btn>
                  </template>
                </v-text-field>
              </template>

              <v-date-picker
                  v-model="creatingHotlineAccess.end"
                  :locale="$i18n.locale"
                  color="primary"
              ></v-date-picker>

            </v-menu>

          </div>

          <client-picker v-model="creatingHotlineAccess.list"></client-picker>

        </v-card-text>
      </vue-scroll>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="reset">Annuler</v-btn>
        <v-btn :color="actionColor ? actionColor : 'success'" @click="save">{{ actionLabel || "Créer"}}</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import moment from "moment";

export default {
  name: "Form",
  components: {
    ClientPicker: () => import("@/Components/Commons/Inputs/Client/ClientPicker")
  },
  props: {
    value: [Array, Object],
    actionLabel: String,
    actionColor: String,
    title: String
  },
  data() {
    return {
      showModal: false,
      errors: {
        title: ""
      },
      creatingHotlineAccess: {
        title: "",
        list: [],
        active: false,
        start: null,
        end: null
      }
    }
  },
  computed: {
    getStart() {
      return this.creatingHotlineAccess.start
          ? moment(this.creatingHotlineAccess.start, 'YYYY-MM-DD').format(this.getLocaleFormat(this.$i18n.locale))
          : ''
    },
    getEnd() {
      return this.creatingHotlineAccess.end
          ? moment(this.creatingHotlineAccess.end, 'YYYY-MM-DD').format(this.getLocaleFormat(this.$i18n.locale))
          : ''
    }
  },
  methods: {
    reset() {
      this.showModal = false

      this.creatingMessage = {
        title: "",
        list: [],
        active: false,
        start: null,
        end: null
      }
      this.errors.title = ""
      this.errors.text = ""
    },
    save() {
      let error = false
      if (!this.creatingHotlineAccess.title) {
        this.errors.title = "Ce champ doit être renseigné"
        error = true
      }
      if (!error) {
        this.$emit('input', this.creatingHotlineAccess)
        this.$emit('save', this.creatingHotlineAccess)
        this.reset()
      }
    },
  },
  watch: {
    showModal(nValue) {
      if (nValue)
        this.creatingHotlineAccess = this.value
    }
  }
}
</script>

<style scoped>

</style>
